.toast-title {
  font-weight: 500;
}
.toast-message {
  -ms-word-wrap: break-word;
      word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #fff;
}
.toast-message a:hover {
  color: #ccd5db;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  top: -.3em;
  right: -.3em;
  float: right;
  margin-bottom: -.5em;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
          text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=80);
  opacity: .8;

  -webkit-text-shadow: 0 1px 0 #fff;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=40);
  opacity: .4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-top-center {
  top: 12px;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  right: 0;
  bottom: 12px;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width .toast {
  margin-bottom: 0;
}
.toast-bottom-full-width {
  right: 0;
  bottom: 0;
  width: 100%;
}
.toast-bottom-full-width .toast {
  margin-bottom: 0;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
[aria-live="polite"] {
  position: fixed;
  z-index: 1700;
  pointer-events: none;
  /*overrides*/
}
[aria-live="polite"] * {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
[aria-live="polite"] > div {
  position: relative;
  width: 300px;
  padding: 15px 20px;
  margin: 0 0 6px;
  overflow: hidden;
  color: #a3afb7;
  pointer-events: auto;
  filter: alpha(opacity=95);
  background-repeat: no-repeat;
  background-position: 15px center;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;
  opacity: .95;
}
[aria-live="polite"] > .toast-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .23);
          box-shadow: 0 1px 3px rgba(0, 0, 0, .23);
}
[aria-live="polite"] > .toast-shadow:hover {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, .23);
          box-shadow: 0 1px 6px rgba(0, 0, 0, .23);
}
[aria-live="polite"] > :hover {
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1;
}
[aria-live="polite"] > .toast-success:not(.toast-just-text),
[aria-live="polite"] > .toast-info:not(.toast-just-text),
[aria-live="polite"] > .toast-warning:not(.toast-just-text),
[aria-live="polite"] > .toast-error:not(.toast-just-text) {
  padding-left: 50px;
}
[aria-live="polite"] > .toast-success:not(.toast-just-text):before,
[aria-live="polite"] > .toast-info:not(.toast-just-text):before,
[aria-live="polite"] > .toast-warning:not(.toast-just-text):before,
[aria-live="polite"] > .toast-error:not(.toast-just-text):before {
  position: absolute;
  top: 50%;
  left: 12px;
  font-family: "Web Icons";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
       -o-transform: translate(0, -50%);
          transform: translate(0, -50%);

  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[aria-live="polite"] > .toast-success {
  color: #fff;
}
[aria-live="polite"] > .toast-success:not(.toast-just-text):before {
  content: "\f192";
}
[aria-live="polite"] > .toast-info {
  color: #fff;
}
[aria-live="polite"] > .toast-info:not(.toast-just-text):before {
  content: "\f19e";
}
[aria-live="polite"] > .toast-warning {
  color: #fff;
}
[aria-live="polite"] > .toast-warning:not(.toast-just-text):before {
  content: "\f1a0";
}
[aria-live="polite"] > .toast-error {
  color: #fff;
}
[aria-live="polite"] > .toast-error:not(.toast-just-text):before {
  content: "\f19a";
}
[aria-live="polite"].toast-top-center > div,
[aria-live="polite"].toast-bottom-center > div {
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}
[aria-live="polite"].toast-top-full-width > div,
[aria-live="polite"].toast-bottom-full-width > div {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0;
}
.toast {
  background-color: #e4eaee;
}
.toast-success {
  background-color: #5cd29d;
}
.toast-error {
  background-color: #fa7a7a;
}
.toast-info {
  background-color: #77d6e1;
}
.toast-warning {
  background-color: #f4b066;
}
.toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: #000;
  filter: alpha(opacity=10);
  opacity: .1;
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  [aria-live="polite"] > div {
    width: 11em;
    padding: 8px 8px 8px 50px;
  }
  [aria-live="polite"] .toast-close-button {
    top: -.2em;
    right: -.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  [aria-live="polite"] > div {
    width: 18em;
    padding: 8px 8px 8px 50px;
  }
  [aria-live="polite"] .toast-close-button {
    top: -.2em;
    right: -.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  [aria-live="polite"] > div {
    width: 25em;
    padding: 15px 15px 15px 50px;
  }
}
