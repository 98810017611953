.has-warning .twitter-typeahead .tt-input,
.has-warning .twitter-typeahead .tt-hint {
  border-color: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-warning .twitter-typeahead .tt-input:focus,
.has-warning .twitter-typeahead .tt-hint:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
}
.has-error .twitter-typeahead .tt-input,
.has-error .twitter-typeahead .tt-hint {
  border-color: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-error .twitter-typeahead .tt-input:focus,
.has-error .twitter-typeahead .tt-hint:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
}
.has-success .twitter-typeahead .tt-input,
.has-success .twitter-typeahead .tt-hint {
  border-color: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-success .twitter-typeahead .tt-input:focus,
.has-success .twitter-typeahead .tt-hint:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
}
.input-group .twitter-typeahead:first-child .tt-input,
.input-group .twitter-typeahead:first-child .tt-hint {
  width: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.input-group .twitter-typeahead:last-child .tt-input,
.input-group .twitter-typeahead:last-child .tt-hint {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.input-group.input-group-sm .twitter-typeahead .tt-input,
.input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: 32px;
  padding: 6px 13px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
select.input-group.input-group-sm .twitter-typeahead .tt-input,
select.input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: 32px;
  line-height: 32px;
}
textarea.input-group.input-group-sm .twitter-typeahead .tt-input,
textarea.input-group.input-group-sm .twitter-typeahead .tt-hint,
select[multiple].input-group.input-group-sm .twitter-typeahead .tt-input,
select[multiple].input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: auto;
}
select.input-group.input-group-sm .twitter-typeahead .tt-input,
select.input-group.input-group-sm .twitter-typeahead .tt-hint {
  padding-top: 0;
  padding-bottom: 0;
}
.input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-input,
.input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0;
}
.input-group.input-group-sm .twitter-typeahead:first-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:first-child .tt-hint {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.input-group.input-group-sm .twitter-typeahead:last-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:last-child .tt-hint {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.input-group.input-group-lg .twitter-typeahead .tt-input,
.input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: 46px;
  padding: 10px 18px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 4px;
}
select.input-group.input-group-lg .twitter-typeahead .tt-input,
select.input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: 46px;
  line-height: 46px;
}
textarea.input-group.input-group-lg .twitter-typeahead .tt-input,
textarea.input-group.input-group-lg .twitter-typeahead .tt-hint,
select[multiple].input-group.input-group-lg .twitter-typeahead .tt-input,
select[multiple].input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: auto;
}
select.input-group.input-group-lg .twitter-typeahead .tt-input,
select.input-group.input-group-lg .twitter-typeahead .tt-hint {
  padding-top: 0;
  padding-bottom: 0;
}
.input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-input,
.input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0;
}
.input-group.input-group-lg .twitter-typeahead:first-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:first-child .tt-hint {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.input-group.input-group-lg .twitter-typeahead:last-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:last-child .tt-hint {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.twitter-typeahead {
  float: left;
  width: 100%;
}
.input-group .twitter-typeahead {
  display: table-cell !important;
}
.twitter-typeahead .tt-hint {
  color: #526069;
}
.twitter-typeahead .tt-input {
  z-index: 2;
}
.twitter-typeahead .tt-input[disabled],
.twitter-typeahead .tt-input[readonly],
fieldset[disabled] .twitter-typeahead .tt-input {
  cursor: not-allowed;
  background-color: #f3f7f9 !important;
}
.tt-dropdown-menu,
.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1200;
  width: 100%;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .05);
          box-shadow: 0 3px 12px rgba(0, 0, 0, .05);
}
.tt-dropdown-menu .tt-suggestion,
.tt-menu .tt-suggestion {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 300;
  line-height: 1.57142857;
  color: #76838f;
}
.tt-dropdown-menu .tt-suggestion.tt-cursor,
.tt-menu .tt-suggestion.tt-cursor,
.tt-dropdown-menu .tt-suggestion:hover,
.tt-menu .tt-suggestion:hover {
  color: #76838f;
  text-decoration: none;
  cursor: pointer;
  background-color: #f3f7f9;
  outline: 0;
}
.tt-dropdown-menu .tt-suggestion.tt-cursor a,
.tt-menu .tt-suggestion.tt-cursor a,
.tt-dropdown-menu .tt-suggestion:hover a,
.tt-menu .tt-suggestion:hover a {
  color: #76838f;
}
.tt-dropdown-menu .tt-suggestion p,
.tt-menu .tt-suggestion p {
  margin: 0;
}
