.strength-container {
  position: relative;
}
.strength-container .strength-input {
  width: 100%;
  padding-right: 20px;
}
.strength-container .strength-meter {
  position: absolute;
  top: 15%;
  right: 6px;
  width: 4px;
  height: 70%;
  overflow: hidden;
  background-color: #ccc;
  border-radius: 1px;
}
.strength-container .strength-score {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  color: transparent;
  -webkit-transition: width .6s ease 0s;
       -o-transition: width .6s ease 0s;
          transition: width .6s ease 0s;
}
.strength-container .show-password-wrap {
  position: absolute;
}
.strength-container .strength-invalid.strength-score {
  height: 25%;
  background-color: #f96868;
}
.strength-container .strength-weak.strength-score {
  height: 50%;
  background-color: #f2a654;
}
.strength-container .strength-good.strength-score {
  height: 75%;
  background-color: #57c7d4;
}
.strength-container .strength-strong.strength-score {
  height: 100%;
  background-color: #46be8a;
}
